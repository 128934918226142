import React from "react";
import { Row, Col, Container, Button} from "react-bootstrap";
import PageWrapper from "../components/PageWrapper";

import imgJorg from "../assets/image/akkadian/DrJorg.jpg";
import imgStefan from "../assets/image/akkadian/Stefan.jpg";
import imgJohn from "../assets/image/akkadian/John.jpg";

import { clinicians, admins, states } from "../utils/team.js";



import Divider from '../sections/akkadian/Divider';
import imgBanner from "../assets/image/akkadian/teamworking.jpg";
import { Helmet } from "react-helmet";
import { Link } from 'gatsby';


const OurTeam = ({location}) => {

    const { state = {} } = location;
    let { provider = null } = location.state || {};
    provider = provider !== null ? provider : "";

    React.useEffect(() => {
        if (location) {
            setTimeout(() => {
                if (provider) {
                    // scroll to id of provider
                    console.log("-------------------------->", provider);
                    const element = document.getElementById(provider);
                    if (element) {
                        element.scrollIntoView({ behavior: 'smooth', block: "center" });
                    } else {
                        console.log("--------------------------> ID Not Found");
                    }
                } else {
                    console.log("--------------------------> No ID provided");
                }
            }, 800);
        }
    }, [provider, location]);

    const stateChips = states.map((state) => (
        <span key={state.name} className="badge badge-pill badge-soft-primary stateChips" style={{ color: state.textColor, backgroundColor: state.color }}>{state.name}</span>
    ));

    
    // Use like this
    // <span className="badge badge-pill badge-soft-primary stateChips" style={{color: "#161c2d", backgroundColor: "#9BCBEB"}}>Example</span>


    return (
    <>
      <PageWrapper
        headerConfig={{
          theme: "light",
          buttonText: "Contact us"
        }}
        footerConfig={{
          theme: "light",
          page: "akkadian-home",
          style: "style2", //style1, style2
        }}
      >
        <Helmet>
          <title>Akkadian Health - Meet Our Team | Telehealth Psychiatrists Australia</title>
		  <meta
                name="description"
                content="Get to know the Akkadian Health team. Our experienced telehealth psychiatrists provide ADHD assessments and mental health services across Australia."
            />
        </Helmet>
				  <style>{`
					table{
					 width:90%;
					}
					table, th, td {
					  border: 1px solid white;
					  border-collapse: collapse;
					  padding: 8px;
					  color: white;
					  margin-left: 5%
					}
					th, td {
					  background-color: #2fbfcf;
					}
				  `}</style>

        <div className="inner-banner">
            <Container>



            <Row id={"about-us"} className="justify-content-center mt-md-3 pt-24 pt-lg-29">
					<Col lg="9" xl="8">
						<div className="px-md-6 text-center mb-11 mb-lg-14">
							<h1 className="title gr-text-2 mb-9 mb-lg-12">About us</h1>
                        </div>
                    </Col>
            </Row>

			<Row className="justify-content-center align-items-center">
				<Col md="12">
					<div className="section-title text-center mb-12 mb-lg-16">
						<p className="gr-text-10 px-lg-8 mb-0">
							Akkadian Health was founded in 2020 by a group of like-minded psychiatrists, technologists and business executives. In the 4 years since, we've delivered mental health services to over 6000 individuals across Australia - from Adult ADHD Assessments, Psychology sessions, and general Psychiatric evaluations through to ADHD Coaching. 
						</p>
						<br />
						<p className="gr-text-10 px-lg-8">
							Our clinical team has grown to include dozens of experienced psychiatrists, psychologists and mental health clinicians all driven by the same goal - to make a population impact on the mental health issues facing everyday Australians.  
						</p>                            
					</div>
				</Col>
			</Row>					

            {/*
            <Row className="justify-content-center align-items-center">
				<Col md="12">
					<div className="section-title text-center mb-12 mb-lg-16">
						<p className="gr-text-10 px-lg-8 mb-0">
							Our clinical team works across the following states:
						</p>
						<br />
						<div className="stateChips d-flex justify-content-center">
						{stateChips.map((chip) => (
							<React.Fragment key={chip.key}>
								{chip}
								&nbsp;
							</React.Fragment>
						))}
						</div>
					</div>
				</Col>
			</Row>
            */}
            <hr />

				<Row className="gr-text-8 mb-6 px-4">
					<h4>Meet our Executive Team</h4>
				</Row>
                <Row className="justify-content-center mb-0">
                    <Col lg="3" md="4" xs="12" className="mb-lg-15 mb-13">
                        <div className="team-card">
                            <div className="card-image" style={{textAlign: 'center'}}>
                                <img src={imgJorg} alt="" className="w-60 rounded-8" />
                                <div className="stateChip">
                                {stateChips.map((stateChip, index) => 
                                    stateChip.props.children === 'SA' ?
                                    stateChip : null
                                )}                                    
                                </div>
                            </div>
                            <div className="card-text pt-5">
                                <h3 className="gr-text-6 mb-0">Associate Professor Jörg Strobel</h3>
                                <p className="gr-text-11 mb-3">
                                    <span className="font-weight-bold">MD, FRANZCP, FGLF</span><br />
                                    Medical Director
                                </p>
                                <p className="gr-text-11 mb-0">
                                    Jörg has served patients in regional and rural South Australia for more than 10 years using telehealth. His extensive telepsychiatry experience, coupled with his 30 years of clinical experience, leads Akkadian Health’s mental health services.
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col lg="3" md="4" xs="12" className="mb-lg-15 mb-13">
                        <div className="team-card">
                            <div className="card-image" style={{textAlign: 'center'}}>
                                <img src={imgStefan} alt="" className="w-60 rounded-8" />
                                <div className="stateChip">
                                {stateChips.map((stateChip, index) => 
                                    stateChip.props.children === 'SA' ?
                                    stateChip : null
                                )}                                    
                                </div>
                            </div>
                            <div className="card-text pt-5">
                                <h3 className="gr-text-6 mb-0">Dr Stefan Enderling</h3>
                                <p className="gr-text-11 mb-3">
                                    <span className="font-weight-bold">M. Eng, PhD, GAICD. </span><br />
                                    Managing Director
                                </p>
                                <p className="gr-text-11 mb-0">
                                    Stefan co-founded Akkadian Health to bring much needed high-quality healthcare to rural and regional Australia. He has over 10 years of experience in developing and leading private and publicly listed life science and technology companies.
                                </p>
                            </div>
                        </div>
                    </Col>
                    <Col lg="3" md="4" xs="12" className="mb-lg-15 mb-13">
                        <div className="team-card">
                            <div className="card-image" style={{textAlign: 'center'}}>
                                <img src={imgJohn} alt="" className="w-60 rounded-8" />
                                <div className="stateChip">
                                {stateChips.map((stateChip, index) => 
                                    stateChip.props.children === 'SA' ?
                                    stateChip : null
                                )}                                    
                                </div>
                            </div>
                            <div className="card-text pt-5">
                                <h3 className="gr-text-6 mb-0">John Fouyaxis</h3>
                                <p className="gr-text-11 mb-3">
                                    <span className="font-weight-bold">BSc, BEngBiomed (Hons), BPsych (Hons)</span><br />
                                    Head of Technology
                                </p>
                                <p className="gr-text-11 mb-0">
                                    John has developed leading-edge digital technologies for over 20 years. Combining this experience with his thirst for knowledge and passion for mental health, John drives the digital health technology offerings of Akkadian Health.
                                </p>
                            </div>
                        </div>
                    </Col>
				</Row>

				<hr />

				<Row id={"clinical"} className="gr-text-8 mb-8 px-4">
					<h4>Meet our Clinical Team</h4>
				</Row>

                <Row className="justify-content-center mb-0">
                    {clinicians.map((clinician, index) => (
                        <Col key={index} lg="3" md="4" xs="12" className="mb-lg-15 mb-13">
                            <hr />                                 
                            <div id={clinician.id} className="team-card">
                                <div className="card-image" style={{textAlign: 'center'}}>
                                    <img src={clinician.imageFile} alt="" className="w-50 rounded-8" />
                                    <div className="stateChip">
                                    {stateChips.map((stateChip, index) => 
                                        stateChip.props.children === clinician.state ?
                                        stateChip : null
                                    )}                                    
                                    </div>
                                </div>
                                <div className="card-text pt-5">
                                    <h3 className="gr-text-6 mb-0">{clinician.name}</h3>
                                    <p className="gr-text-11 mb-3">
                                        <span className="font-weight-bold">{clinician.credentials}</span><br />
                                        {clinician.title}
                                    </p>
                                    <p className="gr-text-12 mb-0">
                                    {clinician.bio}
                                    </p>
                                </div>
                            </div>                           
                        </Col>
                    ))}

				</Row>
				
{/* 				<hr />
				<Row id={"admin"} className="gr-text-8 mb-8 px-4">
					<h4>Our Operations & Admin Team</h4>
				</Row>
				<Row  className="justify-content-center mb-0">
                {admins.map((admin, index) => (
                        <Col key={index} lg="3" md="4" xs="12" className="mb-lg-15 mb-13">
                            <hr />                                 
                            <div id={admin.id} className="team-card">
                                <div className="card-image" style={{textAlign: 'center'}}>
                                    <img src={admin.imageFile} alt="" className="w-30 rounded-8" />
                                    <div className="stateChip">
                                    {stateChips.map((stateChip, index) => 
                                        stateChip.props.children === admin.state ?
                                        stateChip : null
                                    )}                                    
                                    </div>
                                </div>
                                <div className="card-text pt-5">
                                    <h3 className="gr-text-6 mb-0">{admin.name}</h3>
                                    <p className="gr-text-11 mb-3">
                                        <span className="font-weight-bold">{admin.title}</span><br />
                                    </p>
                                </div>
                            </div>                           
                        </Col>
                    ))}
					
                </Row> */}
                <Row className="justify-content-center pt-10 pt-lg-17">
                        <Link to="/for-specialists" className={`btn btn-primary gr-hover-y`}>
                            Join us
                        </Link>
                </Row>
                <br />
            </Container>
        </div>
        <Divider />

				
				
			
      </PageWrapper>
    </>
		
		
    );
};

export default OurTeam;
